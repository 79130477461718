@import "variables";

html,
body,
#root {
    height: 100%;
}

.switch-business-main-content {
    margin: auto;
    margin-top: 10%;
    width: 500px;
}