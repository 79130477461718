@import "variables";

.timeline-wrapper {
  position: relative;
  width: 100%;
}

.timeline-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  border-radius: 3px;
}

.timeline-hover-area {
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 3px;
}

.timeline-hover-area:hover {
  opacity: 0.8;
  background-color: #eaeaea;
}

.timeline-toggle-text {
  font-size: 11px;
  color: #666;
  padding: 1px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.timeline-collapse-indicator {
  font-size: 8px;
  opacity: 0.7;
}

.timeline-container {
  position: relative;
  display: flex;
  justify-content: center;
  transition: opacity 0.2s ease;
  padding: 12px 0;
  min-height: 32px;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
}

.timeline-container.is-loading {
  opacity: 0.6;
  pointer-events: none;
}

.timeline-container::-webkit-scrollbar {
  height: 6px;
}

.timeline-container::-webkit-scrollbar-track {
  background: transparent;
}

.timeline-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

.timeline-items-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 0 64px;
  min-width: min-content;
  margin: 0 auto;
}

.timeline-item {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  min-width: fit-content;
}

.timeline-item:last-child {
  margin-right: 48px;
}

.timeline-content {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  min-width: max-content;
}

.timeline-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
}

.timeline-date {
  font-size: 11px;
  color: #666;
  line-height: 1;
  text-align: center;
}

.timeline-connector {
  width: 120px;
  height: 2px;
  background-color: #ccc;
  flex: none;
  margin: 0 16px;
}

.timeline-refresh {
  display: flex;
  align-items: center;
  margin-left: 16px;
  flex-shrink: 0;
}

@media screen and (max-width: 1200px) {
  .timeline-container {
    justify-content: flex-start;
  }

  .timeline-items-wrapper {
    justify-content: flex-start;
    padding: 0 32px;
  }

  .timeline-connector {
    width: 32px;
    margin: 0 6px;
  }
}