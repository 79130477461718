.awsui awsui-app-layout:not(.awsui-util-no-gutters) .awsui-app-layout:not(.awsui-app-layout-contentType-no-paddings) .awsui-app-layout__content,
.awsui awsui-app-layout:not(.awsui-util-no-gutters) .awsui-app-layout:not(.awsui-app-layout-contentType-no-paddings) .awsui-app-layout__content--scrollable {
  padding: 0;
}

.awsui awsui-app-layout:not(.awsui-util-no-gutters) .awsui-app-layout:not(.awsui-app-layout-contentType-no-paddings) .awsui-app-layout__content div[awsui-app-layout-region] {
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.awsui .awsui-app-layout__content {
  overflow-y: auto;
  background-color: white;
}

div#embeddingContainer {
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
  border-width: 0;
}

.awsui awsui-app-layout.orchestrator:not(.awsui-util-no-gutters)
.awsui-app-layout:not(.awsui-app-layout-contentType-no-paddings)
.awsui-app-layout__content div[awsui-app-layout-region]
span {
  position: absolute;
  width: 100%;
  height: 100%;
}