@import "variables";

html, body, #root {
  height: 100%;
}

.main-header {
  width: 80%;
  margin: auto;
  padding: 0 0 50px 0; 
  margin-top: 5%;
}

div.action-container {
  padding-top: 24px;
}

div.centered-header {
  display: inline-block !important;
  text-align: center !important;
}

.main-content {
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
  padding-bottom: 100px;
}

.arrow-down {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.step-view {
  border: solid 1px $gray-200;
  border-radius: 2px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.4);
}
.step-view > div,
.step-view > div > div {
  border: none !important;
}

.step-view.succeeded > div > div {
  color: white;
  background-color: $green-600 !important;
}

.step-view.succeeded > div > div h2 {
  color: white;
}

.step-view.in_progress > div > div {
  color: white;
  background-color: $blue-600 !important;
}

.step-view.in_progress > div > div h2 {
  color: white;
}

.step-view.failed > div > div {
  color: white;
  background-color: $red-600 !important;
}

.step-view.failed > div > div h2 {
  color: white;
}

.step-view.failed .validation-errors-button {
  margin-left: 16px;
}

.continue-generation {
  margin-right: 16px;
}

.loader {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}